import React from "react";

const Footer = () => {
  return (
    <div className="footers" id="contact">
      <footer className="container-fluid ">
        <div className="container">
        <div className="row pt-4 pb-4 align-items-center">
          <div className="col-md-4">
          <a target="_blank" href="https://t.me/+eK1k5qZb3ThiYjk0"><span><img src="./assets/image/home/telegram.png" className="w-30" alt="Telegram" style={{width:"50px"}}/></span> <span className="text-white">Telegram</span></a>
          </div>
          <div className="col-md-6 text-left">
            <p className="text-white">Copyright © 2024 Sribit.World All rights reserved.</p>

          </div>
        </div>
          {/* <div className="row pt-lg-5 pt-3 align-items-center">
            <div className="col-sm-6 col-md-3 col-12">
              <div className="footerlogo"><img className="w-100 h-100" src="./assets/image/logo/" alt="logo" /></div>
            </div>
            <div className="col-sm-6 col-md-3 col-12">
              <div className="footerheadinglist">Developers</div>
              <ul className="footerlist">
                <li><a href="">Tutorials</a></li>
                <li><a href="">Developer Academy</a></li>
                <li><a href="">Builders Program</a></li>
                <li><a href="">FAQ</a></li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3 col-12">
              <div className="footerheadinglist">EcoSystem</div>
              <ul className="footerlist">
                <li><a href="">Wallets</a></li>
                <li><a href="">Validators</a></li>
                <li><a href="">Case Studies</a></li>
                <li><a href="">Whitepaper</a></li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3 col-12">
            <div className="footerheadinglist">Resourses</div>
              <ul className="footerlist">
                <li><a href="">About</a></li>
                <li><a href="">Contact</a></li>
                <li><a href="">Press Kit</a></li>
                <li><a href="">Blog</a></li>
              </ul>
            </div>
          </div> */}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
