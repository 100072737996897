import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Roadmap from "./Roadmap";
import Popup from "./Popup";

const Hero = () => {
  return (

    <div>
      <div className="sidenav">
      <a href="https://t.me/+eK1k5qZb3ThiYjk0" target="_blank">
        <img src="./assets/image/home/telegram.png" class="w-30" alt="Telegram" />
        </a>
      </div>
      {/* <Popup /> */}
      <Header />
      <div className="container-fluid  homepage hero-1 mt-50">
      <div class="hero-bg-gradient"></div>
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-6 d-flex align-items-start justify-content-center flex-column order-2 order-md-1">
              <h1 className="heading">
              What is the metaverse? An explanation and in-depth guide
              </h1>
              <p className="perograph my-3">
              Metaverse content is any piece of an immersive experience created for this emerging virtual world. Creators use virtual reality and other new technology. Creators can also repurpose traditional content types for the metaverse.
              </p>
              {/* <div className=" perograph2 my-3">
                <strong>Contract Addrss</strong>: 0x0DAFCb4841EB93f7F9bd1851106c2444DD3Cf38D</div>
              <button className="btn bg-white text-black fw-bold w-auto addbtn">Copy Address</button> */}
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="heoimage">
                <img
                  src="./assets/image/1.png"
                  className="w-100 h-100"
                  alt="gif"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
      
        <div className="container-fluid py-lg-5 py-3 aboutsection" id="about">
          <div className="container">
            <div className="row align-items-center mt-10">
              <div className="col-md-7 order-md-1 order-2">
                <div className="sectionheading">Real-world assets (RWAs)
                </div>
                <h5 className="text-white">
                The digital assets fuelling the frictionless future of decentralized finance

                </h5>
                <p className="my-4">The tokenization of real-world assets is galvanizing new opportunities for investment yield in DeFi while unlocking liquidity in traditionally illiquid asset classes.</p>
                <div className="sectionheading">What are real-world assets (RWAs)?

                </div>
                
                <p className="my-4">
                Real-world assets (RWAs) are fungible or non-fungible tokens that represent traditional financial assets on the blockchain. RWAs can represent tangible assets such as real estate or intangible assets such as government bonds and carbon credits.
<br></br>
                Real-world asset tokenization is the process of bringing these assets on-chain as security tokens to take advantage of blockchain technology. Any asset of value can be tokenized and utilized on-chain in transactions.
<br></br>
                Security tokens can be issued to represent real-world assets that exist and are managed off-chain by custodians, or security tokens can be issued to serve as real-world assets themselves. For example, a bond can be issued directly on-chain as a token, or it can be issued and held off-chain.
<br></br>
                Backed by traditional assets, real-world assets can serve as sustainable and reliable digital asset classes that put decentralized finance in direct competition with traditional finance
                                
                </p>
              </div>
              <div className="col-md-5 order-md-2 order-1">
                <div className="image">
                  <img className="w-100 h-100" src="./assets/image/sv.svg" alt="rocket" />
                </div>
              </div>
            </div>
            <br></br> <br></br>
            <div className="row align-items-center" id="depin">
              <div className="col-md-6 ">
                <div className="image">
                  <img className="w-100 h-100" src="./assets/image/defi.png" alt="rocket" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="sectionheading">What does DePIN mean?</div>
                <p className="my-4">Decentralized physical infrastructure networks (DePIN) refer to the application of blockchain technology and decentralization principles to physical infrastructure and systems.
                 <br></br><br/>
                  In recent years, the rise of blockchain technology has catalyzed a paradigm shift in how we perceive and interact with digital systems. From decentralized finance (DeFi) to nonfungible tokens (NFTs), the principles of decentralization have revolutionized various aspects of our digital lives. However, this revolution is not limited to the virtual realm.
                  <br></br><br/>
                  Enter decentralized physical infrastructure networks (DePINs), a novel concept that extends the ethos of decentralization to tangible infrastructure, promising to reshape industries and empower individuals in unprecedented ways. DePINs enable autonomous, real-time interactions inside physical infrastructures through technologies like smart contracts and the Internet of Things (IoT), increasing system responsiveness and adaptability to human demands.</p>
                                
              </div>
            </div>

            <br></br> <br></br>
            <div className="row align-items-center" id="game">
             
              <div className="col-md-6">
                <div className="sectionheading">Game</div>
                <p className="my-4">Games available in most casinos are commonly called casino games. In a casino game, the players gamble cash or casino chips on various possible random outcomes or combinations of outcomes. Casino games are also available in online casinos, where permitted by law. Casino games can also be played outside of casinos for entertainment purposes, like in parties or in school competitions, on machines that simulate gambling.
                  <br></br>
                  There are three general categories of casino games: gaming machines, table games, and random number games. Gaming machines, such as slot machines and pachinko, are usually played by one player at a time and do not require the involvement of casino employees.
                              
             </p>
              </div>
              <div className="col-md-6 ">
                <div className="image">
                  <img className="w-100 h-100" src="./assets/image/game.png" alt="rocket" />
                </div>
              </div>
            </div>

          </div>
        </div>


        <div className="container-fluid py-lg-5 py-3 aboutsection"id="roadmap" >
          <div className="container">
            <div className="row align-items-center mt-10">
              <div className="col-md-12 order-md-1 order-2">
                <div className="sectionheading text-center">Roadmap
                </div>
                <div class="timeline">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="timeline-container">
                          <div class="timeline-end">
                            <p>Start</p>
                          </div>
                          <div class="timeline-continue">

                            <div class="row timeline-right">
                              <div class="col-md-6">
                                <p class="timeline-date">
                                Q3 2024
                                </p>
                              </div>
                              <div class="col-md-6">
                                <div class="timeline-box">
                                  <div class="timeline-icon">
                                    <i class="fa fa-star"></i>
                                  </div>
                                  <div class="timeline-text">
                                    <h3 className="text-white"> Community development start</h3>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row timeline-left">
                              <div class="col-md-6 d-md-none d-block">
                                <p class="timeline-date">
                                Q4 2024
                                </p>
                              </div>
                              <div class="col-md-6">
                                <div class="timeline-box">
                                  {/* <div class="timeline-icon d-md-none d-block">
                                    <i class="fa fa-business-time"></i>
                                  </div> */}
                                  <div class="timeline-text">
                                    <h3 className="text-white">Sribit token (BTS) Launching 
                                    </h3>
                                  </div>
                                  <div class="timeline-icon d-md-block d-none">
                                    <i class="fa fa-briefcase"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 d-md-block d-none">
                                <p class="timeline-date">
                                Q4 2024
                                </p>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
                                <div class="timeline-year">
                                  <p>2025</p>
                                </div>
                              </div>
                            </div>

                            <div class="row timeline-right">
                              <div class="col-md-6">
                                <p class="timeline-date">
                                Q1 2025
                                </p>
                              </div>
                              <div class="col-md-6">
                                <div class="timeline-box">
                                  <div class="timeline-icon">
                                    <i class="fa fa-bullhorn"></i>
                                  </div>
                                  <div class="timeline-text">
                                    <h3 className="text-white">Gaming platform launching 
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row timeline-left">
                              <div class="col-md-6 d-md-none d-block">
                                <p class="timeline-date">
                                Q2 2025
                                </p>
                              </div>
                              <div class="col-md-6">
                                <div class="timeline-box">
                                  <div class="timeline-icon d-md-none d-block">
                                    <i class="fa fa-desktop"></i>
                                  </div>
                                  <div class="timeline-text">
                                    <h3 className="text-white">Metaverse project 
                                    </h3>
                                  </div>
                                  <div class="timeline-icon d-md-block d-none">
                                    <i class="fa fa-desktop"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 d-md-block d-none">
                                <p class="timeline-date">
                                Q2 2025
                                </p>
                              </div>
                            </div>

                            <div class="row timeline-right">
                              <div class="col-md-6">
                                <p class="timeline-date">
                                Q3 2025
                                </p>
                              </div>
                              <div class="col-md-6">
                                <div class="timeline-box">
                                  <div class="timeline-icon">
                                    <i class="fa fa-diamond"></i>
                                  </div>
                                  <div class="timeline-text">
                                    <h3 className="text-white">RWA Launch 
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row timeline-left">
                              <div class="col-md-6 d-md-none d-block">
                                <p class="timeline-date">
                                Q4 2025
                                </p>
                              </div>
                              <div class="col-md-6">
                                <div class="timeline-box">
                                  <div class="timeline-icon d-md-none d-block">
                                    <i class="fa fa-home"></i>
                                  </div>
                                  <div class="timeline-text">
                                    <h3 className="text-white">DEPIN launch</h3>
                                  </div>
                                  <div class="timeline-icon d-md-block d-none">
                                    <i class="fa fa-home"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 d-md-block d-none">
                                <p class="timeline-date">
                                Q4 2025
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="timeline-start">
                            <p>End</p>
                          </div>
                          {/* <div class="timeline-launch">
                            <div class="timeline-box">
                              <div class="timeline-text">
                                <h3>Launched our company on 01 Jan 2019</h3>
                                <p>Lorem ipsum dolor sit amet</p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid py-lg-5 py-4 Benefits " >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center  mb-5">
                <div className="sectionheading mb-3">Why Choose Us</div>
                {/* <p className="subheading ">Hybrid crypto offers several key benefits that can revolutionize the cryptocurrency market</p> */}
              </div>
            </div>
            <div className="row justify-content-md-center g-4">
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Security.svg" alt="icon" />
                    </div>
                    <div className="heading">Security</div>
                    <p>Ensures a higher level of security for user</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Efficiency.svg" alt="icon" />
                    </div>
                    <div className="heading">Efficiency</div>
                    <p>Streamlines processes, increases overall efficiency.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Decision-making.svg" alt="icon" />
                    </div>
                    <div className="heading">Decision-making</div>
                    <p>Decisions based on real-time market conditions.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Predictive Insights.svg" alt="icon" />
                    </div>
                    <div className="heading">Predictive Insights</div>
                    <p>Insights into market trends and investment opportunities.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/User-Friendly Interfaces.svg" alt="icon" />
                    </div>
                    <div className="heading">User-Friendly Interfaces</div>
                    <p>Makes it easier for both novice and experienced users.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-lg-5 py-3 white-papersection">
          <div className="container">
            
            <div className="row align-items-center getsection">
              <div className="col-md-6">
                <div className="headingget">Get involved</div>
                <ul className="socialicons mt-5">
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/twitter.png" className="w-100 h-100" alt="Twitter"/></span> Twitter</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/facebook.png" className="w-100 h-100" alt="Medium"/></span> Facebook</a></li>
                  <li><a target="_blank" href="https://t.me/+eK1k5qZb3ThiYjk0"><span><img src="./assets/image/home/telegram.png" className="w-100 h-100" alt="Telegram"/></span> Telegram</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/instagram.png" className="w-100 h-100" alt="Discord"/></span> Instagram</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/discord.png" className="w-100 h-100" alt="Discord"/></span> Discord</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/youtube.png" className="w-100 h-100" alt="Discord"/></span> Youtube</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/link.png" className="w-100 h-100" alt="Discord"/></span> Linktr</a></li>

                </ul>                  

              </div>
              <div className="col-md-6">
                <div className="card getcard p-sm-5 p-2">
                  <div className="card-body">
                      <div className="card-heading mb-4">start trading on Sribit</div>
                      <button className="btn getbtn fw-bold py-3 px-3">GET STARTED</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </>

      <Footer />
    </div>
  );
};

export default Hero;
