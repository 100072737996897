import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="xl"
        className="bge bgNav nav-underline"
       
      >
        <Container>
          <Navbar.Brand href="/" style={{ height:"90px"}}>
            {" "}
            <img  className="w-100 h-100" src="./assets/image/logo/logo.png"alt="logo"  />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto mainbtn1">
              <Nav.Link className="lenk text-white" href="#Home">
                METAVERSE
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#about">
                RWA
              </Nav.Link>
              {/* <Nav.Link className="lenk text-white" href="Whitepaper.pdf" target="_blank">
                White Paper
              </Nav.Link> */}
              <Nav.Link className="lenk text-white" href="#depin">
                DEPIN
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#game">
                GAME
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#roadmap">
                ROADMAP 
              </Nav.Link>
              <Link
                className="btn mainbtn px-lg-4 py-lg-2 text-white mb-2 mb-lg-0"
                to="/login"
              >
                Login
              </Link>
              <Link
                className="btn mainbtn px-lg-4 py-lg-2 text-white ms-md-2"
                to="/Register"
              >
                Register
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
